<template>
  <div class="score">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'Score',

    metaInfo() {
      return {
        title: 'Статистика',
      }
    },

    created() {
      this.$centrifuge.subscribe( 'event-history', message => {
        console.log( 'message', message ) // todo remove

        this.$root.$emit( 'EVENT_HISTORY_CREATED', message )
      } )
    },
  }
</script>
